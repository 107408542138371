import { createContext, useContext, type ReactNode } from 'react'
import { type CMSLayout } from '@/components/CMS/types'

type Props = { data: CMSLayout['content_type_id']; children: ReactNode }

const CMSLayoutContext = createContext<CMSLayout['content_type_id'] | null>(
  null
)

export const CMSLayoutContextProvider = ({ children, data }: Props) => {
  return (
    <CMSLayoutContext.Provider value={data}>
      {children}
    </CMSLayoutContext.Provider>
  )
}

export const useCMSLayoutContext = () => useContext(CMSLayoutContext)
