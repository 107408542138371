import { CMSSection } from '@/components/CMS/CMSSection'
import {
  type CmsMappedComponents,
  type CoreLayoutContentTypes,
} from '@/components/CMS/types'
import dynamic from 'next/dynamic'
import { type ComponentProps } from 'react'

/* PLOP_INJECT_DYNAMIC_IMPORT */

const DynamicStoresCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/StoresCarousel')).StoresCarousel
)
const DynamicPastOrdersShelf = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/PastOrdersShelf'))
      .PastOrdersShelf
)
const DynamicPromoBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/PromoBanner')).PromoBanner
)
const DynamicSmallNav = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/SmallNav')).SmallNav
)
const DynamicFullWidthBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/FullWidthBanner'))
      .FullWidthBanner
)
const DynamicLargePromoCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/LargePromoCarousel'))
      .LargePromoCarousel
)

const DynamicCampaignPromoBanner = dynamic(
  async () =>
    (
      await import(
        '@/components/CMS/components/Shelves/CMSCampaignPromoBanner/CMSCampaignPromoBanner'
      )
    ).CMSCampaignPromoBanner
)
const DynamicMultiRetailerProducts = dynamic(
  async () =>
    (
      await import(
        '@/components/CMS/components/Shelves/MultiRetailerProductShelves'
      )
    ).MultiRetailerProductShelves
)
const DynamicHeroCardCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/HeroCardCarousel'))
      .HeroCardCarousel
)
const DynamicSmallPromoCarousel = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/SmallPromoCarousel'))
      .SmallPromoCarousel
)
const DynamicCMSCouponShelf = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/CMSCouponShelf'))
      .CMSCouponShelf
)
const DynamicCMSProductShelf = dynamic(
  async () =>
    (
      await import(
        '@/components/CMS/components/Shelves/ProductShelf/CMSProductShelf'
      )
    ).CMSProductShelf
)
const DynamicStandardNav = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/StandardNav'))
      .StandardNav
)
const DynamicAdBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/CMSAdBanner')).AdBanner
)
const DynamicEnhancedNav = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/EnhancedNav'))
      .EnhancedNav
)
const DynamicEnhancedProduct = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/EnhancedProduct'))
      .EnhancedProduct
)
const DynamicAppDownloadBanner = dynamic(
  async () =>
    (await import('@/components/CMS/components/Shelves/CMSAppDownloadBanner'))
      .AppDownloadBanner
)

export const coreLayoutCmsComponents = {
  /* PLOP_INJECT_CMS_SECTION_COMPONENT */
  ad_banner: DynamicAdBanner,
  app_download_banner: DynamicAppDownloadBanner,
  campaign_promo_banner: DynamicCampaignPromoBanner,
  coupon_shelf: DynamicCMSCouponShelf,
  enhanced_nav: DynamicEnhancedNav,
  enhanced_product: DynamicEnhancedProduct,
  full_width_banner: DynamicFullWidthBanner,
  hero_card_carousel: DynamicHeroCardCarousel,
  large_promo_carousel: DynamicLargePromoCarousel,
  multi_retailer_product_shelves: DynamicMultiRetailerProducts,
  past_orders_shelf: DynamicPastOrdersShelf,
  product_shelf: DynamicCMSProductShelf,
  promo_banner: DynamicPromoBanner,
  small_nav: DynamicSmallNav,
  small_promo_carousel: DynamicSmallPromoCarousel,
  standard_nav: DynamicStandardNav,
  stores_carousel: DynamicStoresCarousel,
} satisfies CmsMappedComponents<CoreLayoutContentTypes['content_type_id']>

export const CoreLayoutCMSSection = (
  props: Omit<ComponentProps<typeof CMSSection>, 'components'>
) => <CMSSection {...props} components={coreLayoutCmsComponents} />
