import { type ReactNode, createContext, useMemo, useContext } from 'react'
import { toCouponsById } from '@/services/Coupons/utils'
import { type Coupon, type CouponsById } from '@/services/Coupons/types'

const CouponsContext = createContext<CouponsById | null>(null)

type Props = { coupons: Coupon[]; children: ReactNode }

export const CouponsContextProvider = ({ children, coupons }: Props) => {
  const value = useMemo(() => toCouponsById(coupons ?? []), [coupons])
  return (
    <CouponsContext.Provider value={value}>{children}</CouponsContext.Provider>
  )
}

export const useCouponsContext = () => useContext(CouponsContext)
